@import '../../styles/variables';

.root {
  background: transparent;
  border: 1px solid $color--highlight;
  bottom: 0;
  font-family: inherit;
  font-size: 14px;
  left: 0;
  margin: 0 auto 25px;
  max-width: calc(100% - 60px);
  min-height: 50px;
  min-width: 44px;
  padding: 1.6em 1em;
  right: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  &[disabled] {
    cursor: default;
    opacity: 0.5;
    //display: none;
  }

  &:active:not([disabled]) {
    background: $color--highlight--dark;
  }
}

.inner {
  color: $color--highlight;
  font-size: 1em;
  font-weight: bold;
  letter-spacing: 0.025em;
  position: relative;
  z-index: 1;
}

.root:active:not([disabled]) .inner {
  color: $color--black;
}

.subdued {
  opacity: 0.5;
}

:global(#fbLoginButton) {
  bottom: 15px;
  position: absolute;
}
