@import './variables';
//@import 'loaders.css/src/animations/ball-grid-pulse.scss';

html,
body {
  background-color: $color--main;
  color: $color--highlight;
  font-family: $font--main;
  height: 100%;
  margin: 0;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  padding: 0;
  width: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}
