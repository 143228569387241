@import '../../../../styles/variables';

.root {
  border: solid 1px $color--highlight;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 10px 30px 0 30px;
  padding: 15px;
}

.item {
  width: 25%;
}

.item rect {
  transition: all 0.2s;
}

.button {
  appearance: none;
  background-color: $color--main;
  border: 0;
  display: block;
  line-height: 0;
  margin: 0;
  padding: 0;
  position: relative;
}

.active {
  border: 2px solid $color--highlight;

  &::after {
    background: url('./check.svg') no-repeat bottom right;
    background-size: contain;
    bottom: 5px;
    content: '';
    height: 40px;
    position: absolute;
    right: 5px;
    width: 40%;
  }

  &:focus {
    outline: none;
  }
}

.color {
  width: 100%;
}
