@import '../../styles/variables';

.root {
  background: $color--highlight--dark;
  border: none;
  bottom: 0;
  font-family: inherit;
  font-size: 1em;
  left: 0;
  min-height: 46px;
  min-width: 44px;
  position: fixed;
  right: 0;
  text-transform: uppercase;
  width: 100%;
  z-index: 100;

  @media (min-height: 900px) {
    min-height: 60px;
  }

  &[disabled] {
    background-color: $color--black;
    border: 1px solid darken($color--highlight, 35%);
    cursor: default;
  }

  &:active:not([disabled]) {
    background: $color--highlight--dark;
  }
}

.inner {
  color: $color--black;
  font-size: 0.8em;
  font-weight: bold;
  letter-spacing: 0.025em;
  position: relative;
  z-index: 1;
}

.root[disabled] .inner {
  color: darken($color--highlight, 35%);
}

.root:active:not([disabled]) .inner {
  color: $color--black;
}

.default {
  border-color: $color--secondary;
}

.subdued {
  opacity: 0.5;
}
