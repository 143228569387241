@import '../../styles/variables';

.root {
  align-items: center;
  border: 0;
  display: grid;
  flex-grow: 1;
  grid-template-rows: auto 1fr;
  margin: 0 0 60px;
  min-height: calc(100vh - 240px);
  padding: 0 0 1em;
  position: relative;
  text-align: left;
  width: 100%;
  z-index: 100;
}
