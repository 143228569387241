.root {
  > label,
  > input,
  > fieldset > ul {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }

  > div > label,
  > div > input,
  > div > fieldset > ul {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }
}
