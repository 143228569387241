@import '../../styles/variables';

.root {
  background-color: transparent;
  border: 0;
  border-bottom: solid 1px $color--highlight;
  border-radius: 0;
  //color: $color--highlight;
  color: $color--white;
  font-family: inherit;
  font-size: 20px;
  margin: 0.5em 40px 0 30px;
  padding: 0 0 10px 15px;
  position: relative;
  width: calc(100% - 60px);

  &:focus {
    outline: none;
  }
}

.leveled {
  margin-bottom: 15px;
  padding-bottom: 17px;
}
