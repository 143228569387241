@import '../../../../styles/variables';

.root {
  background-color: $color--main;
  border: 1px solid $color--highlight;
  color: $color--highlight;
  display: grid;
  font-family: inherit;
  font-size: 110px;
  grid-template-columns: repeat(6, 1fr);
  height: 135px;
  letter-spacing: 30px;
  position: relative;
  text-align: center;
  width: 100%;
}

.num {
  align-items: center;
  display: flex;
  justify-content: center;
  letter-spacing: 0;
  position: relative;

  &::before,
  &::after {
    border-left: 1px solid $color--highlight;
    content: '';
    height: 10px;
    left: 0;
    position: absolute;
    top: 0;
  }

  &::after {
    bottom: 0;
    top: inherit;
  }

  &:first-child::before,
  &:first-child::after {
    border-left: 0;
  }
}

.cursor {
  background-color: $color--highlight;
  height: 80%;
  margin-left: 10px;
  position: absolute;
  top: 10%;
  width: 1px;

  :global {
    animation: 1s blink infinite linear;
  }
}

@keyframes :global(blink) {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
