:global(.geosuggest__suggests--hidden) {
  border-width: 0;
  max-height: 0;
  overflow: hidden;
}

.list {
  list-style: none;
  margin: 0 30px;
  padding: 0;
}

.item {
  border: solid white;
  border-width: 0 1px 1px 1px;
  color: white;
  padding: 14px;
}

.active {
  background-color: white;
  color: black;
}
