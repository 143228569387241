.root {
  align-items: center;
  background-color: #dfe3e8;
  border: 2px solid #fff;
  border-radius: 20px;
  color: #454f5b;
  display: inline-flex;
  font-size: 13px;
  line-height: 20px;
  padding: 0 10px;
}

.progressCircle {
  border: 2px solid currentColor;
  border-radius: 50%;
  color: #919eab;
  height: 10px;
  margin: 0 4px 0 -5px;
  width: 10px;
}

.incomplete {
  background: transparent;
}

.partiallyComplete {
  background: linear-gradient(0deg, currentColor, currentColor 50%, transparent 0, transparent);
}

.complete {
  background: currentColor;
}

.success {
  background-color: #bbe5b3;
  color: #414f3e;

  & .progressCircle {
    color: #108043;
  }
}

.info {
  background-color: #b4e1fa;
  color: #3e4e57;

  & .progressCircle {
    color: #084e8a;
  }
}

.attention {
  background-color: #ffea8a;
  color: #595130;

  & .progressCircle {
    color: #9c6f19;
  }
}

.warning {
  background-color: #ffc58b;
  color: #594430;

  & .progressCircle {
    color: #c05717;
  }
}

.new {
  background-color: #47c1bf;
  border: none;
  border-radius: 1rem;
  color: #003135;
  font-weight: 500;
  padding: 0 0.8rem;

  & .progressCircle {
    color: #919eab;
  }
}
