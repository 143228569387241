@import '../../styles/variables';

.root {
  margin: 0;
}

.button {
  > a,
  > button {
    display: block;
    position: relative;
    width: 100%;
  }
}

.horizontal {
  display: flex;
}

.vertical .button {
  margin-bottom: 1em;
}

.horizontal .button {
  flex: 1;
}

.vertical .button:last-child {
  margin-bottom: 0;
}
