@import '../../../../styles/variables';

.select {
  background-color: transparent;
  border: 1px solid $color--secondary-dark;
  border-radius: 0;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  color: $color--secondary;
  font-family: $font--main;
  font-size: 16px;
  margin-top: 0.5em;
  padding: 1.5rem;
  position: relative;
  width: 100%;
}
