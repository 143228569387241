.enter {
  opacity: 0.01;
  //transform: scale(0.9) translateY(50%);
}

.enterActive {
  opacity: 1;
  //transform: scale(1) translateY(0%);
  transition: all 300ms ease-out;
}

.exit {
  //transform: scale(1) translateY(0%);
  display: none;
  opacity: 1;
}

.exitActive {
  opacity: 0.01;
  //transform: scale(0.9) translateY(50%);
  transition: all 300ms ease-out;
}

.exitDone {
  display: none;
}

.data {
  font-size: 2em;
  min-height: 135px;
}

.data > div > span > b {
  font-family: monospace;
  margin-left: 0.5em;
  padding: 1px;
}

.bcOrderId {
  background: white;
  border: 1px solid white;
  border-radius: 5px;
  color: black;
}

.orderId {
  background: #222;
  border: 1px solid #222;
  border-radius: 5px;
}

.page > div > div {
  display: block;
  flex: auto;
}
