@import '../../styles/variables';

.root {
  background: no-repeat top center;
  background-size: contain;
  color: $color--white;
  display: flex;
  flex: 1;
  flex-direction: column;
  // justify-content: space-between;
  margin: 0 auto;
  max-width: 32rem;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}

.centered {
  justify-content: center;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px 30px 0 30px;
  overflow: hidden;
  text-align: left;

  > label,
  > input,
  > fieldset > ul {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }

  > div > label,
  > div > input,
  > div > fieldset > ul {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }
}

.specialMode {
  color: red !important;
}
