@import '../../../../styles/variables';

.root {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
}

.check {
  border: 1px solid $color--highlight;
  flex-shrink: 0;
  height: 45px;
  position: relative;
  width: 45px;

  &::before {
    background-color: transparent;
    content: '';
    height: 30px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
  }
}

.small .check {
  height: 25px;
  width: 25px;

  &::before {
    height: 12px;
    width: 12px;
  }
}

.checkbox:checked + .check::before {
  background-color: $color--highlight--dark;
}

.checkbox {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  opacity: 0;
  overflow: hidden !important;
  padding: 0 !important;

  /* Need to make sure we override any existing styles. */
  /* stylelint-disable declaration-no-important */
  position: absolute !important;
  top: 0;
  width: 1px !important;
  /* stylelint-enable declaration-no-important */
}

.text {
  color: $color--white;
  margin-left: 10px;
}

.text a {
  color: $color--white;
}
