.root {
  padding: 0 30px;
}

.title {
  font-size: 20px;
}

.image {
  width: 100%;
}
