@import '../../styles/variables';

.root {
  color: $color--secondary;
  display: flex;
  height: 35px;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 54em;
  padding: 0.75em 30px 2px;
  position: relative;
  transition: margin 0.3s;
  width: 100%;
  z-index: 101;
}

.info {
  background-color: transparent;
  border: 1px solid $color--secondary;
  border-radius: 50%;
  color: $color--secondary;
  display: block;
  height: 1.75em;
  margin-left: auto;
  padding: 0;
  width: 1.75em;
}

.breadcrumb {
  background-color: transparent;
  border: 0;
  color: $color--highlight;
  font-family: inherit;
  font-size: $font--small;
  padding: 0;
  text-align: left;
  text-decoration: none;
  transition: margin 0.3s;
  width: 100%;
}

.hide {
  margin-top: -35px;
  opacity: 0;
  pointer-events: none;
  transition: margin 0.3s;
}

.button {
  background-color: transparent;
  border: 0;
  color: $color--secondary;
  font-size: 20px;
  line-height: 1;
  padding: 0 4px 4px;
  padding-top: 0;
}
