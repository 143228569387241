.VisuallyHidden {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;

  /* Need to make sure we override any existing styles. */
  /* stylelint-disable declaration-no-important */
  position: absolute !important;
  top: 0;
  width: 1px !important;
  /* stylelint-enable declaration-no-important */
}
