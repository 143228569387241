@import '../../../../styles/variables';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.buttonAdd {
  align-self: flex-end;
  background-color: transparent;
  border: 1px solid $color--highlight--dark;
  border-radius: 0;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  color: $color--secondary;
  font-family: $font--main;
  height: 43px;
  margin-right: 30px;
  margin-top: 1.5em;
  position: relative;
  width: 43px;

  &::before,
  &::after {
    background-color: $color--white;
    content: '';
    height: 25px;
    left: 50%;
    position: absolute;
    top: 8px;
    width: 25px;
  }

  &::before {
    height: 1px;
    top: 20px;
    transform: translate(-50%, -50%);
  }

  &::after {
    width: 1px;
  }

  & span {
    bottom: 0;
    font-size: 12px;
    left: 0;
    position: absolute;
    right: 0;
    transform: translateY(115%);
    white-space: nowrap;
  }
}

.fields {
  margin-top: 20px;
}
