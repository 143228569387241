@import '../../../../styles/variables';

.root {
  display: flex;
  flex-direction: column;
  margin: 16px 0 12px;
  padding: 0 30px;
}

.root label {
  margin: 10px 0 0;
}

.input {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 8px;
  height: 7px;
  margin: 14px 0 0;
  position: relative;
  width: 100%;
  z-index: 50;
}

//stylelint-disable
@mixin thumb {
  background-color: #329c00;
  border: 2px solid $color--highlight;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  margin-top: -14px;
  transition: all 0.2s ease;
  width: 40px;
}

.input::-webkit-slider-thumb {
  -webkit-appearance: none;

  @include thumb;
}

.input::-moz-range-thumb {
  @include thumb;
}

@mixin track {
  background: $color--black;
  border: 2px solid $color--highlight--dark;
  height: 14px;
}

.input::-webkit-slider-runnable-track {
  @include track;
}

.input::-moz-range-track {
  @include track;
}
//stylelint-enable

.labels {
  display: flex;
  list-style: none;
  margin: 12px 0 6px;
  padding: 0;
  position: relative;

  &::before,
  &::after {
    border: 2px solid $color--highlight--dark;
    border-bottom: 0;
    border-top: 0;
    content: '';
    height: 20px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transform: translateY(-100%);
    width: calc(100% - 4px);
  }

  &::after {
    border: 0 dotted $color--highlight--dark;
    border-bottom-width: 1px;
    top: 32px;
  }
}

.hasChildren .labels {
  &::after {
    display: none;
  }
}

.label {
  flex: 1;
  padding: 0;
  text-align: center;

  &:first-child {
    text-align: left;
    transform: translateX(-26px);
  }

  &:last-child {
    text-align: right;
    transform: translateX(26px);
  }
}

.button {
  background: transparent;
  border: 0;
  color: $color--white;
  font-family: inherit;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.image {
  height: auto;
  width: 100%;
}

// stylelint-disable
.hasMoved .input {
  &::-ms-thumb {
    background-color: darken($color--highlight--dark, 25%);
    border-color: darken($color--highlight--dark, 5%);
  }

  &::-webkit-slider-thumb {
    background-color: darken($color--highlight--dark, 25%);
    border-color: darken($color--highlight--dark, 5%);
  }

  &::-moz-range-thumb {
    background-color: darken($color--highlight--dark, 25%);
    border-color: darken($color--highlight--dark, 5%);
  }
}

.root:last-child {
  .labels::after {
    display: none;
  }
}
// stylelint-enable

.disabled {
  opacity: 0.5;
}
