@import '../../styles/variables';

.root {
  color: $color--highlight;
  font-size: 24px;
  transition: opacity 0.3s;
}

.hide {
  opacity: 0;
}
