@import '../../styles/variables';

.root {
  color: $color--white;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

h1.root {
  color: $color--positive;
  font-size: 36px;
  font-weight: 100;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
}
