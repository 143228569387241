@import '../../styles/variables';

.root {
  border: 1px solid $color-all;
  color: transparent;
  display: block;
  font-size: 2.5em;
  font-weight: 400;
  line-height: 34px;
  margin: 10px 30px 0 30px;
  padding: 13px;
  text-align: center;
}

.mounted {
  color: $color-all;
}

.primary {
  font-size: 1.5em;
}

.secondary,
.level4 {
  border: 1px dotted #42cc00;
  border-bottom: 0;
  border-right: 0;
  font-size: 1em;
  line-height: 1.3;
  margin-bottom: 0;
  padding: 10px 11px 0;
  text-align: left;
}

.secondary:nth-of-type(2n) {
  margin-top: 0;
}

.level4 {
  border: 0;
  color: $color--highlight--dark;
  margin-top: 10px;
  padding: 0;
}

.hidden {
  display: none;
  font-size: 0;
}
