@import '../../../../styles/variables';

.fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.root {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mounted .root {
  border: 1px solid $color-all;
  display: block;
  margin: 10px 30px 30px 30px;
  padding: 0;
}

.radio {
  margin: 0;
  opacity: 0;
  padding: 0;
}

.choice {
  background: transparent;
  border: 0;
  border-bottom: 1px solid $color-all;
  color: #756d6a;
  width: 100%;

  &:last-child {
    border-bottom: 0;
  }
}

.label {
  align-items: center;
  color: $color-all;
  display: inline-flex;
  font-size: 1em;
  font-weight: 400;
  min-height: 45px;
  padding: calc(0.1em + 2vh) 14px calc(0.1em + 2vh) 0;
  position: relative;
  width: 100%;
  z-index: 1;
}

.text {
  display: block;

  &::before {
    background-color: $color--highlight--dark;
    content: '';
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    transition: height 0.1s;
    width: 100%;
    z-index: -100;
  }

  & small {
    color: #fff;
    display: block;
  }
}

.radio:checked + .text {
  color: black;
}

.radio:checked + .text::before {
  height: 101%;
}

.radio:checked + .content::after {
  background: url('../Color/check.svg') no-repeat bottom right;
  background-size: contain;
  bottom: 0;
  content: '';
  height: 40px;
  position: absolute;
  right: 5px;
  width: 40%;
  z-index: 10;
}

.radio:focus + .text {
  font-weight: bold;

  & small {
    font-weight: normal;
  }
}

.grid {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);

  &::before {
    content: '';
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    padding-bottom: 100%;
    width: 0;
  }

  & > *:first-child {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }

  & .content,
  & .label {
    height: 100%;
    position: relative;
    width: 100%;
  }
}

.image {
  height: auto;
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
}
