// Colors
$color--main: #000;
$color--secondary: #b2aaa6;
$color--secondary-dark: #756d6a;
$color--white: #fff;
$color--black: #000;
$color--positive: #52ff00;
$color--negative: #c1a17a;

$color-all: $color--positive;
$color--highlight: $color-all;
$color--highlight--dark: darken($color--highlight, 10%);

// Gradients
$gradient--main: linear-gradient(90deg, $color--negative, $color--positive);
$gradient--main-vertical: linear-gradient(#fd4137, #d69923, $color--positive);

// Fonts
$font--main: 'Karla','Helvetica Neue',sans serif;
$font--small: 0.8em;
$font--xsmall: 0.7em;
$font--bold: 700;
$font--normal: 400;
$font--light: 400;

// Sizes
$size--radius: 0.5rem;
