@import '../../styles/variables';

.root {
  margin-top: 40px;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0;
}

.section {
  color: $color--secondary;
  padding: 20px 0;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 20px;

  & > a,
  & > button {
    margin: 0;
    max-width: inherit;
    width: 100%;
  }
}
