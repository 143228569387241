.root {
  margin: 1em 0;
  text-align: center;
}

.root p {
  font-size: 13px;
  line-height: 160%;
  margin-bottom: 0;
  margin-top: 13px;
  text-align: center;
}

.root .intro {
  color: #fff;
  font-size: 18px;
  line-height: 140%;
  margin-left: 0;
  padding-top: 18px;
}
